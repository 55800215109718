import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IDataTableComponentProps } from "./types"
import CircularProgress from '@mui/material/CircularProgress';
import styles from './data-table.module.scss';
import classNames from "classnames"
import EmptyPageContent from "../../../infrastructure/components/EmptyPageContent";

function DataTableComponent({
    rows,
    columns,
    onCellClick,
    checkboxSelection = false,
    toolbar,
    isFetching = false,
    className,
    idChangeValue
}: IDataTableComponentProps) {

    return (
        <div
            className={classNames({
                [`${styles.table_container}`]: true,
                [`${styles.is_loading}`]: isFetching,
            })}
        >
            {isFetching ?
                <CircularProgress className="circular-progress" size={60} /> :
                rows.length > 0 ? <DataGrid
                    rows={rows || []}
                    columns={columns || []}
                    className={className}
                    hideFooterPagination={true}
                    hideFooter={true}
                    // initialState={{
                    //     pagination: {
                    //         paginationModel: { page: 0, pageSize: 10 },
                    //     },
                    // }}
                    // pageSizeOptions={[5, 10]}
                    checkboxSelection={checkboxSelection}
                    onCellClick={onCellClick}
                    getRowId={(row) => row[idChangeValue]}
                    slots={{
                        toolbar,
                    }}
                    sx={{ fontFamily: "OnestMedium" }}
                /> : <EmptyPageContent />
            }
        </div>

    );
};

export default DataTableComponent;
