import {
    CHANGE_LANGUAGE,
    SET_CUSTOMER_INFO,
    UPLOAD_COMPANY_INFO,
    SHOW_SIDE_DRAWER,
    SAVE_SETTINGS,
    SAVE_MARKET_HOURS,
    ON_START_MARKET
} from '../actions';
import i18n from "../../i18n";
import { storePrefLang } from "../services/Prefs";
import moment from "moment";

export const setCustomerInfo = (stateName, value, block) => {
    return { type: SET_CUSTOMER_INFO, payload: { stateName, value, block } };
}

export const uploadCompanyDetails = (payload) => {
    return { type: UPLOAD_COMPANY_INFO, payload };
}

export const saveMarketHours = (time) => {
    return { type: SAVE_MARKET_HOURS, payload: { ...time } };
}

export const onStartMarket = (state) => {
    return { type: ON_START_MARKET, payload: state };
}

export const onShowSideDrawer = () => {
    return { type: SHOW_SIDE_DRAWER };
}

export const saveSettings = (payload) => {
    return { type: SAVE_SETTINGS, payload };
}

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
    storePrefLang(lng);
    return { type: CHANGE_LANGUAGE, payload: lng };
}
