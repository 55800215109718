import React, { useEffect } from 'react';
import SignInPage from './pages/SignInPage'
// import Dashboard from './pages/Dashboard'
import Companies from './pages/CompaniesPage'
import History from './pages/HistoryPage'
import Orders from './pages/Orders'
import Trades from './pages/Trades'
// import Payments from './pages/PaymentsPage'
// import CustomerLicenses from './pages/CustomerLicenses'
import CompanyAddEdit from './pages/CompanyAddEdit'

import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getToken, getAccessToken } from './api/selectors/tokenSelector'
import { removeToken, removeUserData, removeExpiredTime, removeRefreshToken, storeToken, storeRefreshToken, storeExpiredTime } from "./api/services/Auth";
import { logoutStart, logoutFinish, setToken, setExpiredTime, signIn, authEnd, setTokenInHeaders, setTokenExpired } from './api/actionCreators/authActionCreator';
// import { getLocalstorageToken, isTokenPresent } from "./api/services/Auth";
// import axios from "axios";

import './App.scss';

const App = () => {
    const dispatch = useDispatch();

    const token = useSelector(getToken)
    const accessToken = useSelector(getAccessToken)
    // const testToken = isTokenPresent() ? getLocalstorageToken() : "";
    useEffect(() => {
        // refreshToken()
        if( !token && !accessToken) {
             removeExpiredTime()
             removeRefreshToken()
             dispatch(logoutFinish())
             dispatch(setToken({ accessToken: "", refreshToken: "", expiresIn: "" }))
             dispatch(setExpiredTime(null))
             dispatch(signIn(null))
             removeToken()
             removeUserData()
         } 
        //  else{
        //    // axios.defaults.headers.common['Authorization'] = "Bearer " + testToken;
        //     dispatch(setTokenInHeaders(true))
        //     dispatch(setTokenExpired(false))
        //  }
     }, [token])

    return (
        <Switch>
            <Route path="/" exact component={SignInPage} />
            <Route path="/app/orders" exact component={Orders} />
            <Route path="/app/trades" exact component={Trades} />
            <Route path="/app/history" exact component={History} />
            <Route path="/app/my-companies/info/:companyId" exact component={CompanyAddEdit} />
            <Route path="/app/my-companies/new" exact component={CompanyAddEdit} />
            <Route path="/app/my-companies" exact component={Companies} />

            {/* <Route path="/app/dashboard" exact component={Dashboard} /> */}
             {/* <Route path="/app/history/:customerId" exact component={CustomerLicenses} /> */}
            {/* <Route path="/app/payments" exact component={Payments} /> */}
              {/* < <Route path="/app/kyc-data" exact component={KycData} /> */}
            {/* <Route path="/app/history" exact component={Customers} /> */}
            <Redirect to="/app/orders" />
        </Switch>
    );

};

export default App;
