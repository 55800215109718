import React, { useState, useEffect, useCallback } from "react";
import AppMainLayout from '../../infrastructure/layouts/AppMainLayout'
import DataTableComponent from "../../infrastructure/components/DataTableComponent"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SearchIcon } from "../../svg-icons/icon-search"
import { ExitIcon, Button } from "finergy-kit"
import { amountFormatter } from "../../constants"
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import axios from "../../api/services/Api";
import { getTokenInHeaders } from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination"
import moment from "moment"

import styles from './trades.module.scss';


const Transactions = () => {
    const { t } = useTranslation();
    const [isFetching, setIsFetching] = useState(true);
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [rows, setRows] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [searchText, setSearchText] = useState("")

    const tokenInHeader = useSelector(getTokenInHeaders);

    const fetchTrades = useCallback(() => {
        setIsFetching(true)
        axios.get(`/trades?offset=${currentPage * pageSize}&count=${pageSize}&searchAll=${searchText}`)
            .then(res => {
                const { data: { tradeList, totalCount } } = res
                setRows(tradeList || [])
                setTotalCount(totalCount)
                setIsFetching(false)
            })
    }, [searchText, currentPage, pageSize])

    useEffect(() => {
        fetchTrades()
    }, [searchText, currentPage, pageSize, fetchTrades])

    useEffect(() => {
        setIsFetching(true);
        tokenInHeader && fetchTrades();
    }, [tokenInHeader, fetchTrades]);



    const renderLicensesCells = () => {
        return [
            {
                field: 'tradeId',
                headerName: "ID " + t("Trades"),
                flex: 1,
                headerClassName: styles.table_header,
                renderCell: (row) => {
                    return `#${row.value}`;
                },
            },
            { field: 'sellOrderId', headerName: 'Заявка на продажу', flex: 1, headerClassName: styles.table_header, },
            { field: 'buyOrderId', headerName: 'Заявка на покупку', flex: 1, headerClassName: styles.table_header, },
            {
                field: 'fromAmount', headerName: t("Sale"), flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return `${amountFormatter(row.fromAmount)} ${row.fromCcy}`
                },
            },
            {
                field: 'toAmount', headerName: t("Buy"), flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return `${amountFormatter(row.toAmount)} ${row.toCcy}`
                },
            },
            {
                field: 'fixRate', headerName: t("Exchange_rate"), flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return `${amountFormatter(row.fixRate)} MDL`;
                },
            },
            {
                field: 'buyCommissionAmount',
                headerName: t('Buy_commision'),
                flex: 1,
                headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return `${amountFormatter(row.buyCommissionAmount)}`;
                },
            },
            {
                field: 'sellCommissionAmount',
                headerName: t('Sell_commision'),
                flex: 1,
                headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return `${amountFormatter(row.sellCommissionAmount)}`;
                },
            },
            {
                field: 'whenCreated', headerName: t('Created'), flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return moment(row.whenCreated).format("DD-MM-YYYY HH:mm");
                },
            },

        ];
    }

    const paginationClick = (page) => {
        setIsFetching(true);
        setCurrentPage(page)
    }

    const pageSizeClick = (size) => {
        setCurrentPage(0)
        setIsFetching(true);
        setPageSize(size)
    }

    return (
        <AppMainLayout navBarTitle={t('Trades')} >

            {/* {isRedirect && <Redirect to={`/licenses/${redirectId}`} />} */}
            <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
                <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<SearchIcon />}
                    label="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </FormControl>

            <DataTableComponent
                // onCellClick={(row) => onCellClick(row)}
                checkboxSelection={false}
                columns={renderLicensesCells()}
                isFetching={isFetching}
                idChangeValue={"tradeId"}
                {...{ rows }} />
            {!isFetching && <Pagination
                onClick={paginationClick}
                onChangePageSize={pageSizeClick}
                total={totalCount}
                pageIndex={currentPage}
                pageSize={pageSize}
            />}

            <div className={styles.customer_page_bottom_container}>
                <Button
                    variant="secondary"
                    size="l"
                    icon={<ExitIcon />}
                    disabled
                    label={t('Export_as_csv')}
                    iconPosition="right"
                    id={"EXPORT_CSV"}
                />

            </div>
        </AppMainLayout>
    )
}

export default Transactions

