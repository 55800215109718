import React, { useEffect, useState } from "react";
import InfoBlock from "./info-block"
import { useDispatch, useSelector } from 'react-redux';

import { setCustomerInfo, uploadCompanyDetails } from '../../../../api/actionCreators/mainActionCreator';
import { getCustomerInfo } from '../../../../api/selectors/mainSelector';
import { PARENT_LIST, currensyList } from "../../../../constants/data"
import { checkDisabledButton } from "../../../../constants"
// import {  } from '../../../../svg-icons/icon-save';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, SaveIcon } from 'finergy-kit';
import axios from "../../../../api/services/Api";
import { useTranslation } from 'react-i18next';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Redirect } from 'react-router-dom';
import classNames from "classnames"
import { enqueueSnackbar } from 'notistack';
import styles from './tab-info.module.scss';
import { PercentIcon } from '../../../../svg-icons/icon-percent';
import { getAccessToken } from '../../../../api/selectors/tokenSelector'

// const config = require('../../../../config.json');
// import { createServer } from 'miragejs';
// import companiesHandler from '../../../../api/miragejs/handlers/companiesHandler';
// createServer({
//     routes() {
//         companiesHandler(this, config.serviceURL)
//         this.passthrough()
//     }
// })

const TabInfo = ({ companyId = null, setCompanyName }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const fetch = !companyId ? false : true
    const [isFetching, setIsFetching] = useState(fetch)
    const [isSaving, setIsSaving] = useState(false)
    const [isCountsSaving, setIsCountsSaving] = useState(false)
    const [getBack, setGetBack] = useState(false)
    const [counts, setCounts] = useState({})
    const [uploadedData, setUploadedData] = useState(null)

    const [countsData, setCountsData] = useState({ accountList: [] })
    const customerInfo = useSelector(getCustomerInfo)
    const { general, users, commision } = customerInfo

    const accessToken = useSelector(getAccessToken)



    const emptyData = {
        general: {
            ...general,
            values: {
                idno: "",
                companyName: "",
                description: "",
            }
        },
        users: {
            ...users,
            values: {
                directorNameSurname: "",
                directorEmail: "",
                directorIdnp: "",
                directorPhone: "",
                directorId: "",
            },
        },
        commision: {
            ...commision,
            values: {
                buyCommission: "",
                sellCommission: "",
            }

        }
    }

    const getCompanyInfo = () => {
        setTimeout(() => {
            axios.get(`/company/${companyId}`).then((response) => {
                const { data } = response


                setCompanyName(data.companyInfo.companyName)
                const updatedData = {
                    general: {
                        ...general,
                        values: {
                            idno: data.companyInfo.idno,
                            companyName: data.companyInfo.companyName,
                            description: data.companyInfo.comment,
                        }
                    },
                    users: {
                        ...users,
                        values: {
                            directorNameSurname: data.directorInfo.directorFullName,
                            directorEmail: data.directorInfo.email,
                            directorIdnp: data.directorInfo.idnp,
                            directorPhone: data.directorInfo.phone,
                            directorId: data.directorInfo.userId,
                        },
                    },
                    commision: {
                        ...commision,
                        values: {
                            buyCommission: data.commissionInfo.buyCommission,
                            sellCommission: data.commissionInfo.sellCommission,
                        }

                    }
                }

                dispatch(uploadCompanyDetails(updatedData))
                setIsFetching(false)
                setIsSaving(false)
                setUploadedData(data)
                setIsCountsSaving(true)
                getAccounts()
            }).catch((err) => {

            });
        }, 1000);

    }
    useEffect(() => {
        if (!companyId) {
            dispatch(uploadCompanyDetails(emptyData))
        } else {
            accessToken &&
                getCompanyInfo()
        }

    }, [accessToken, companyId])

    const getAccounts = () => {
        setTimeout(() => {
            accessToken && axios.get(`/company/account/${companyId}`).then((response) => {
                const { data } = response
                setCountsData({ accountList: data?.accountList })
                let obj = {}
                if (data.accountList.length > 0) {
                    data.accountList.forEach(({ currency, number }) => {
                        obj = {
                            ...obj,
                            [currency]: number
                        }
                    })
                }
                setCounts({ ...obj })
                setIsCountsSaving(false)
            }).catch(() => {
                setCountsData({ accountList: [] })
            })
        }, 1000);

    }
    // useEffect(() => {
    //     companyId && getAccounts()
    // }, [])

    useEffect(() => {
        const updatedData = {
            general: {
                ...general,
                values: {}
            },
            users: {
                ...users,
                values: {},
            },
        }
        dispatch(uploadCompanyDetails(updatedData))
    }, [getBack])

    const saveCountList = () => {
        setIsCountsSaving(true)
        let isMdlCount = false
        let isOneNotMdlCount = false

        currensyList.forEach(curr => {
            if (curr === "MDL" && !!counts[curr] && counts[curr].length > 0) isMdlCount = true
            if ((curr === "EUR" || curr === "USD") && !!counts[curr] && counts[curr].length > 0) isOneNotMdlCount = true
        })

        if (isMdlCount && isOneNotMdlCount) {
            let i = 0;
            (currensyList || []).forEach(curr => {
                i++
                const findCountValueByCurr = countsData.accountList.find(c => c.currency === curr)
                // if(counts[curr] && counts[curr] !== )

                const params = {
                    number: counts[curr],
                    currency: curr,
                }

                const isCountAndEdited = findCountValueByCurr && findCountValueByCurr.number !== counts[curr];
                const noCountAndAdded = !findCountValueByCurr && counts[curr] && counts[curr] !== "";

                if (noCountAndAdded) {
                    axios.post(`/company/account/${companyId}`, { ...params }).then((response) => {
                        const { data } = response
                        setCountsData({ accountList: data.accountList })
                        enqueueSnackbar(t('OK'))
                    })
                } else if (isCountAndEdited && counts[curr] !== "") {
                    axios.put(`/accounts/${findCountValueByCurr.accountId}`, { ...params })
                        .then(() => {
                            enqueueSnackbar(t('Count_was_updated', { count: curr }))
                        })
                        .catch(() => {
                            enqueueSnackbar(t('Error_updating'))
                        })
                } else if (isCountAndEdited && counts[curr] === "") {
                    if (curr === "MDL") {
                        enqueueSnackbar(t('Count_mdl_cannot_be_deleteted'))
                    } else {
                        axios.delete(`/accounts/${findCountValueByCurr.accountId}`)
                            .then(() => {
                                enqueueSnackbar(t('Count_was_deleted', { count: curr }))
                            })
                            .catch(() => {
                                enqueueSnackbar(t('Error_updating'))
                            })
                    }

                }
            })
            i === currensyList.length && getAccounts()
        } else {
            enqueueSnackbar(t('Counts_rules_excepted'))
            getAccounts()
        }

    }

    const saveCompany = () => {
        setIsSaving(true)
        const params = {
            companyInfo: {
                companyId: Number(companyId),
                companyName: general.values.companyName,
                idno: general.values.idno,
                comment: general.values.description,
            },
            directorInfo: {
                userId: users.values.directorId,
                directorFullName: users.values.directorNameSurname,
                idnp: users.values.directorIdnp,
                email: users.values.directorEmail,
                phone: users.values.directorPhone,
            },
            commissionInfo: {
                sellCommission: commision.values.sellCommission,
                buyCommission: commision.values.buyCommission,
            }
        }
        axios.put(`/company/${companyId}`, { ...params }).then((response) => {
            // setIsSaving(false)
            getCompanyInfo()
            getAccounts()
            enqueueSnackbar(t('Company_successfully_updated'))
        })
    }

    const createcompany = () => {
        const params = {
            companyInfo: {
                companyName: general.values.companyName,
                idno: general.values.idno,
                comment: general.values.description,
            },
            directorInfo: {
                userId: users.values.directorId,
                directorFullName: users.values.directorNameSurname,
                idnp: users.values.directorIdnp,
                email: users.values.directorEmail,
                phone: users.values.directorPhone,
            },
            commissionInfo: {
                sellCommission: commision.values.sellCommission,
                buyCommission: commision.values.buyCommission,
            }
        }
        axios.post(`/company`, { ...params }).then(() => {
            getAccounts()
        })
    }

    const onChange = (state, value, block) => {

        dispatch(setCustomerInfo(state, value, block))
    }

    const onChangeCountsInfo = (value, curr) => {

        const re = /^[0-9]+$/;
        if (value === '' || re.test(value)) {
            setCounts({
                ...counts,
                [curr]: value
            })
        }
    }

    const renderCountsList = () => {

        const countsList = currensyList.map(curr => {
            return (
                <div className={styles.info_block_content}>
                    <div className={styles.info_block_input_container}>
                        <label>{t("Count_number")} {curr}</label>

                        <TextField
                            //value={count?.number}
                            value={counts[curr]}
                            onChange={(e) => onChangeCountsInfo(e.target.value, curr)}
                        />
                    </div>
                </div>
            )
        })

        return countsList
    }

    const renderCountsContainer = () => {


        let disabled = 0

        currensyList.forEach((currency) => {
            const findCountValueByCurr = (countsData?.accountList || []).find(c => c.currency === currency)
            if (findCountValueByCurr?.number !== counts[currency]) {
                disabled++
            }
        })
        const isDisabled = !companyId || disabled === 0 || isCountsSaving
        return (
            <div className={styles.info_block}>
                <div className={styles.info_block_title}>
                    {t('Counts')}
                    <Button
                        size={"l"}
                        label={t('Save')}
                        onClick={() => isDisabled ? {} : saveCountList()}
                        variant="primary"
                        icon={<SaveIcon />}
                        disabled={isDisabled}
                        iconPosition="right"
                        className={styles.save_button}
                        id={"SAVE_COUNTS"}
                    />
                </div>
                <div
                    className={classNames({
                        [styles.counts_container]: true,
                        [styles.counts_is_fetching]: isCountsSaving
                    })}
                >
                    {isCountsSaving ? <CircularProgress className="circular-progress" size={60} /> : renderCountsList()}
                </div>
            </div>
        )
    }

    const back = () => {
        dispatch(uploadCompanyDetails(emptyData))
        setGetBack(true)
    }

    const disabledSaveButton = uploadedData ? checkDisabledButton(uploadedData, customerInfo) : true

    return (
        <div>
            {getBack && <Redirect to={"/my-companies"} />}
            <div
                className={classNames({
                    [styles.top_content]: true,
                    [styles.center_position]: isFetching
                })}
            >

                {isFetching ? <CircularProgress className="circular-progress" size={60} /> :
                    <>
                        <div className={styles.left_forms}>
                            <InfoBlock
                                title={PARENT_LIST.general.title}
                                fields={general}
                                {...{ onChange }}
                                parent={PARENT_LIST.general.value} />

                            <InfoBlock
                                title={PARENT_LIST.users.title}
                                fields={users}
                                {...{ onChange }}
                                parent={PARENT_LIST.users.value} />
                        </div>
                        <div className={styles.right_forms}>
                            {renderCountsContainer()}
                            <InfoBlock
                                title={PARENT_LIST.commision.title}
                                fields={commision}
                                useEndAdornment
                                adornmentIcon={<PercentIcon />}
                                {...{ onChange }}
                                parent={PARENT_LIST.commision.value} />
                        </div>
                    </>

                }

            </div>

            {!isFetching &&
                <div className={styles.actions_buttons}>
                    <Button
                        className={styles.save_button}
                        onClick={() => back()}
                        variant="tertiary"
                        // disabled={disabled}
                        label={t("Cancel")}
                        size="l"
                        // icon={icon}
                        iconPosition="off"
                        // iconOnly={disabled}
                        id={"GET_BACK"}
                    />

                    <Button
                        variant="primary"
                        icon={<SaveIcon />}
                        className={styles.save_button}
                        size="l"
                        disabled={isSaving || disabledSaveButton}
                        iconPosition="right"
                        label={t('Save')}
                        id={"SAVE_BACK"}
                        onClick={() => !companyId ? createcompany() : disabledSaveButton ? {} : saveCompany()}
                    />
                </div>}
        </div>
    )
}

export default TabInfo

