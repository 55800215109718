import { ListDottIcon } from "../svg-icons/icon-list-dott"

const renderErorLabel = (errLabel, clName, t) => {
    return <div className={`error-label ${clName}`}>
        <ListDottIcon />
        {t(errLabel)}
    </div>
}

export const UseErrorLabel = (err, t) => {
    let errLabel;

    switch ((err).toLowerCase()) {
        case "error":
            errLabel = renderErorLabel(err, "red", t);
            break;
        case "deleted":
        case "expired":
            errLabel = renderErorLabel(err, "gray", t);
            break;

        case "reverted":
            errLabel = renderErorLabel(err, "dark-gray", t);
            break;
        case "active":
            errLabel = renderErorLabel(err, "blue", t);
            break;
        case "completed":
        case "success":
            errLabel = renderErorLabel(err, "green", t);
            break;
        case "in_process":
            errLabel = renderErorLabel(err, "yellow", t);
            break;
        default:
            errLabel = "";
            break;

    }

    return errLabel
}