import React, { useEffect, useState } from "react";
import AppMainLayout from '../../infrastructure/layouts/AppMainLayout'
import DataTableComponent from "../../infrastructure/components/DataTableComponent"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SearchIcon } from "../../svg-icons/icon-search"
import { ExitIcon, PlusIcon, Button, CloseRemoveIcon } from "finergy-kit"
import { PencilIcon } from "../../svg-icons/icon-pencil";
import classNames from "classnames"
import moment from "moment"
import { Redirect } from 'react-router-dom';
import axios from "../../api/services/Api";
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useSelector } from 'react-redux';
import { TrashIcon } from "../../svg-icons/icon-trash";
import styles from './companies-page.module.scss';
import Pagination from "../../infrastructure/components/Pagination"
import {
    getMaintenanceModeStatus
} from "../../api/services/Auth";
import './companies-page.module.scss';
// import { setPageReloaded, onFireRefreshToken, setTokenExpired } from '../../api/actionCreators/authActionCreator';
// import { getAccessToken, getTokenInHeaders, getTokenExpired, getExpiredTime } from '../../api/selectors/tokenSelector'
// import { checkIfTokenExpired } from "../../constants"

// const config = require('../../config.json');
// import { createServer } from 'miragejs';
// import companiesHandler from '../../api/miragejs/handlers/companiesHandler';
// createServer({
//     routes() {
//         companiesHandler(this, config.serviceURL)
//         this.passthrough()
//     }
// })

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CompaniesPageContent = () => {

    const { t } = useTranslation();

    const [redirectId, setRedirectId] = useState(null)
    const [rows, setRows] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [searchText, setSearchText] = useState("")
    const [isFetching, setIsFetching] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false)

    const status = useSelector(getMaintenanceModeStatus);

    useEffect(() => {
        fetchCompanies()
    }, [searchText, currentPage])

    useEffect(() => {
        fetchCompanies();
    }, [currentPage, pageSize]);

    const fetchCompanies = () => {

        setIsFetching(true)
        axios.get(`/company?offset=${currentPage * pageSize}&count=${pageSize}&searchAll=${searchText}`)
            .then(res => {
                const { data: { companyListInfo, totalCount } } = res
                setRows(companyListInfo || [])
                setTotalCount(totalCount)
                setIsFetching(false)
            })

    }

    const onCellClick = ({ row }) => {
        setRedirectId(row.companyId)
        setIsRedirect(true)
    }

    const renderLicensesCells = () => {
        return [
            {
                field: 'id',
                headerName: '#',
                flex: 1,
                headerClassName: styles.table_header,
                renderCell: (row) => {
                    return `#${row.id}`;
                },
            },

            { field: 'idno', headerName: 'IDNO', flex: 1, headerClassName: styles.table_header, cellClassName: styles.bold_black, },
            { field: 'companyName', headerName: t('Company_name'), flex: 1, headerClassName: styles.table_header, cellClassName: styles.bold_black, },
            { field: 'directorEmail', headerName: t('Email'), flex: 1, headerClassName: styles.table_header, cellClassName: styles.bold_blue, },
            {
                field: 'directorPhone',
                headerName: t('Phone'), flex: 1,
                headerClassName: styles.table_header,
                cellClassName: styles.bold_blue,
                renderCell: ({ row }) => {
                    return `+${row.directorPhone}`;
                },
            },
            {
                field: 'whenCreated', headerName: t('When_created'), flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return moment(row.whenCreated).format("DD-MM-YYYY HH:mm");
                },
            },
            {
                field: 'activity',
                headerName: '',
                flex: 1,
                headerClassName: styles.table_header,
                cellClassName: styles.action_buttons,
                renderCell: (row) => {
                    return renderActivity(row);
                },
            },
        ];
    }

    const renderActivity = (row) => {
        return (
            <div className={styles.action_row_container}>
                <PencilIcon onClick={() => onCellClick(row)} className={styles.pencil_icon} />
                <TrashIcon onClick={() => setShowDeleteModal(true)} className={styles.trash_icon} />
            </div>
        )
    }

    const onDeleteCompany = () => {
        setShowDeleteModal(false)
    }

    const renderDeleteModal = () => {
        return (
            <Dialog
                open={showDeleteModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowDeleteModal(false)}
                aria-describedby="alert-dialog-slide-description"

            >
                <div className={styles.custom_modal_container}>
                    <CloseRemoveIcon className={styles.close_icon} />
                    <DialogTitle>{t("Are_you_sure_to_delete_company")}</DialogTitle>
                    <DialogContent>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowDeleteModal(false)}
                            variant='tertiary'
                            label={t("Cancel")}
                            
                            size="l"
                            iconPosition="off"
                            id={"CANCEL_DELETE_MODAL"}
                        />
                        <Button
                            onClick={() => onDeleteCompany()}
                            variant='secondary'
                            label={t("Delete")}
                            className={styles.cancel_button}
                            size="l"
                            iconPosition="off"
                            id={"ON_DELETE_MODAL"}
                        />
                    </DialogActions>
                </div>

            </Dialog>
        )
    }
    const paginationClick = (page) => {
        setCurrentPage(page)
    }
    const pageSizeClick = (size) => {
        setCurrentPage(0)
        setPageSize(size)
    }

    return (
        <AppMainLayout navBarTitle={t('Companies')}>
            {isRedirect && <Redirect to={`/app/my-companies/info/${redirectId}`} />}
            {/* {redirectToAddNewCompany && <Redirect to={`/my-companies/new`} />} */}

            {showDeleteModal && renderDeleteModal()}


            <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
                <InputLabel htmlFor="outlined-adornment-amount">{t('Search')}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<SearchIcon />}
                    label={t('Search')}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </FormControl>

            <div
                className={classNames({
                    [styles.table_container]: true,
                    [styles.is_fetching]: isFetching
                })}
            >
                <DataTableComponent
                    columns={renderLicensesCells()}
                    className={styles.companies_page}
                    isFetching={isFetching}
                    idChangeValue={"companyId"}
                    {...{ rows }}
                />
                {!isFetching && <Pagination
                    onClick={paginationClick}
                    onChangePageSize={pageSizeClick}
                    total={totalCount}
                    pageIndex={currentPage}
                    pageSize={pageSize}
                />}
            </div>


            <div className={styles.customer_page_bottom_container}>
                <Button
                    variant="secondary"
                    size="l"
                    icon={<ExitIcon />}
                    label={t('Export_as_csv')}
                    iconPosition="right"
                    id={"EXPORT_CSV"}
                    disabled={true}
                />
                <Button
                    variant="primary"
                    size="l"
                    icon={<PlusIcon />}
                    label={t('Add_new')}
                    disabled={true}
                    iconPosition="right"
                    className={styles.svg_white}
                    //onClick={() => setRedirectToAddNewCompany(true)}
                    id={"ADD_NEW"}
                />
            </div>
        </AppMainLayout >
    )
}

export default CompaniesPageContent

