import Store from "../store"
import axios from "axios";
import moment from "moment"
import {
    getToken,
    isTokenPresent,
    storeToken,
    storeRefreshToken,
    storeExpiredTime,
    removeToken,
    removeUserData,
    removeExpiredTime,
    removeRefreshToken
} from "../services/Auth";

Store.subscribe(listener);

function select(state) {
    return state.TokenReducer;
}

function listener() {

    const testToken = isTokenPresent() ? getToken() : "";
    //console.log("+++++++++++++++++++++++++++ ", testToken, "///", testToken?.length)
    const token = select(Store.getState()) || null;
    if (testToken?.length > 0) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + testToken;
    }

}

axios.defaults.baseURL = process.env.PUBLIC_URL;

axios.interceptors.request.use(async (config) => {
    if (config.url === "/refreshToken" || config.url === "/login") {
        config.headers.Authorization = ``;
        return config
    }
    const sessionToken = localStorage.getItem('_session_token');
    const rToken = localStorage.getItem('_refresh_token');
    const expTime = localStorage.getItem('_expired_time');

    const now = moment()
    const secDiff = now.diff(expTime, "seconds");

    const diff = (secDiff * -1) < 20 ? true : false
    if (diff) {
        try {
            const params = {
                refreshToken: rToken,
            }
            const response = await axios.post('/refreshToken', { ...params });
            const { data: { accessToken, expiresIn, refreshToken } } = response
            storeToken(accessToken)
            storeRefreshToken(refreshToken)
            const updatedDate = moment().add(expiresIn, 'seconds');
            storeExpiredTime(updatedDate)

        } catch (error) {
            removeExpiredTime()
            removeRefreshToken()
            removeToken()
            removeUserData()
            window.location.reload()
            return Promise.reject(error);
        }
    }
    if (config.url !== "/refreshToken" && config.url !== "/login") {
        config.headers.Authorization = `Bearer ${sessionToken}`;
    } else {
        config.headers.Authorization = ``;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => response,
    error => {
        // if (error.response.status === 401) {
        //     //TO DO: later need to logic with logout
        // }
        return error;
    });

export default axios;
