import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

import { IFormLabeledInputProps } from "./types"
import classNames from "classnames"
import styles from './form-labeled-input.module.scss';
import { EyeIconOff } from "../../../../svg-icons/icon-eye-off"

function FormLabeledInput({ label,
    disabled,
    onChange,
    type,
    onFocus,
    showPasswordText,
    value,
    iconLeft = null,
    iconRight = null,
    id
}: IFormLabeledInputProps) {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div
            className={classNames({
                [`${styles.from_labeled_input_wrapper}`]: true,
                [styles.styles_with_right_icon]: iconRight,
                'disabled': !disabled
            })}>
            {iconLeft && <div className={styles.icon_left}>
                {iconLeft}
            </div>}
            <TextField
                style={{ width: "100%" }}
                className={classNames({
                    [styles.text_input]: true,

                })}
                placeholder={label}
                id={id}
                variant="outlined"
                value={value}
                // label={label}
                type={showPassword ? "text" : type}
                onFocus={onFocus}
                onChange={onChange}
            />
            {iconRight &&
                <div
                    className={classNames({
                        [styles.icon_right]: true,
                        [styles.show_password]: showPassword,
                    })}
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {!showPassword ? iconRight : <EyeIconOff />}
                </div>}
        </div>
    );
};

export default FormLabeledInput;
