import React, { useState, useEffect } from 'react';
import { ITopNavBarComponentProps } from "./types"
import { NotificationsIcon } from "../../../svg-icons/icon-notifications"
import { SettingsIcon } from "../../../svg-icons/icon-settings"
import { PersonIcon } from "../../../svg-icons/icon-person"
import Drawer from '@mui/material/Drawer';
import FormLabeledInput from '../FormComponents/FormLabeledInput/FormLabeledInput';
import classNames from "classnames"
import { Redirect } from 'react-router-dom';
import styles from './top-nav-bar.module.scss';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { CloseRemoveIcon } from '../../../svg-icons/icon-close-remove';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthData } from '../../../api/selectors/authSelector'
import { useTranslation } from 'react-i18next';
import SettingsModal from './settings-modal';

function TopNavBarComponent({ navBarTitle, linkTitle, link, onClick, children, current, className }: ITopNavBarComponentProps) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [redirect, setRedirect] = useState(false)
    const [showSettingsModal, setShowSettingsModal] = useState(false)
    const data = useSelector(getAuthData);
    const authData = JSON.parse(data);


    useEffect(() => {
        !authData?.login && setRedirect(true)
    }, [authData])

    const renderSettingDetails = () => {
        return (
            <Drawer
                anchor={"right"}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <div className={styles.settings_drawer_wrapper}>
                    <div className={styles.drawer_title_container}>
                        <div className={styles.drawer_title}>Change account password</div>
                        <IconButton onClick={() => setIsOpen(false)} className={styles.drawer_close_icon}>
                            <CloseRemoveIcon />
                        </IconButton>
                    </div>
                    <div className={styles.drawer_content}>
                        <div className={styles.setting_content_wrapper}>
                            <FormLabeledInput
                                label="New password"
                                type="password"
                            // onChange={''}
                            />
                            <FormLabeledInput
                                label="Confirm new password"
                                type="password"
                            // onChange={''}
                            />
                        </div>
                    </div>

                    <div className={styles.buttons}>
                        <Button
                            variant="outlined"
                            onClick={() => { }}
                        // disabled={isFetching}
                        // className={classNames({
                        //     [`${styles.no_margin}`]: isFetching,
                        //     [`${styles.saved}`]: isSaved,
                        // })}
                        >
                            Save
                            {/* {!isFetching && !isSaved ? <SaveIcon /> :
                                    isSaved ? <CheckedIcon /> :
                                        <CircularProgress size={20} sx={{ margin: 0 }} />} */}
                        </Button>
                    </div>

                </div>
            </Drawer>


        )
    }

    return (
        <>
            {renderSettingDetails()}
            <SettingsModal {...{ setShowSettingsModal, showSettingsModal }} />
            {redirect && <Redirect to="/" />}
            <div
                className={classNames({
                    [`${styles.top_nav_bar_wrapper}`]: true,
                    [className]: true
                })}>
                <div className={styles.top_bar_page_name}>

                    <Typography className={styles.page_title} component={'span'} variant={'body2'}>{navBarTitle}</Typography>


                </div>
                <div className={styles.top_bar_page_user_wrapper}>
                    <div className={styles.notification_icon_wrapper} >
                        <NotificationsIcon />
                    </div>
                    <div className={styles.settings_icon_wrapper} >
                        <SettingsIcon onClick={() => setShowSettingsModal(true)} />
                    </div>
                    <div className={styles.user_name_info_wrapper}>
                        <span>{authData?.login}</span>
                        <PersonIcon onClick={() => setIsOpen(true)} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopNavBarComponent;
