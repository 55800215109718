import React from "react";
import TextField from '@mui/material/TextField';
import { CloseRemoveIcon } from "../../../../../svg-icons/icon-close-remove"
import styles from './info-block.module.scss';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import classNames from "classnames"

const InfoBlock = ({ title, fields, onChange, parent, useEndAdornment = false, adornmentIcon = null }) => {
    const { t } = useTranslation();

    const renderSelect = (items, inputLabel, stateName) => {
        return (
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t(inputLabel)}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label={t(inputLabel)}
                // onChange={(e) => onChange(stateName, e, parent)}
                >

                    {items.map(i => <MenuItem value={i}>{i}</MenuItem>)}
                </Select>
            </FormControl>
        )
    }
    const renderFields = () => {
        const { fieldsList, values } = fields

        const inputs = fieldsList.map(({
            inputLabel,
            placeholder,
            multiLine,
            type,
            disabled = false,
            stateName,
            variant,
            fileData,
            options }, id) => {
            const label = type !== "file" && inputLabel

            const textFieldProps = {
                placeholder: t(placeholder),
                variant,
                label: t(label),
                multiLine,
                type,
                id,
                value: values[stateName],
                disabled
            }
            return (
                <>
                    <div 
                      className={classNames({
                        [styles.info_block_input_container]: true,
                        [styles.endAdornment]: useEndAdornment
                    })}
                    >
                        <label>{t(inputLabel)}</label>
                        {!options ?
                            useEndAdornment ?
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                    <OutlinedInput
                                        endAdornment={<InputAdornment position="end">{adornmentIcon}</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        className={styles.percent_width}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        {...textFieldProps}
                                    onChange={(e) => onChange(stateName, e.target.value, parent)}
                                    />
                                </FormControl>
                                :
                                <TextField
                                    {...textFieldProps}
                                    onChange={(e) => onChange(stateName, e.target.value, parent)}
                                /> : renderSelect(options, inputLabel, stateName)}
                    </div >
                    {fileData && renderFileInfo(fileData)
                    }
                </>
            )
        })

        return inputs
    }

    const renderFileInfo = (fileData) => {
        const { imgBase64, fileName } = fileData

        return (
            <div className={styles.logo_input_container}>
                <div className={styles.img_container} style={{ background: `url(${`data:image/png;base64,${imgBase64}`})` }} />
                <label>{fileName}</label>
                <CloseRemoveIcon className={styles.close_remove_icon} />
            </div>
        )
    }

    return (
        <div className={styles.info_block}>
            <div className={styles.info_block_title}>
                {t(title)}
            </div>
            <div className={styles.info_block_content}>
                {renderFields()}
            </div>

        </div>
    )
}

export default InfoBlock

