import React, { useState } from "react";
import AppMainLayout from '../../infrastructure/layouts/AppMainLayout'
import Heading from '../../infrastructure/components/Heading'
import { useParams } from 'react-router-dom';
import TabInfo from "./tabs-content/tab-info";
// import TabOperations from "./tabs-content/tab-operations";
import { useTranslation } from 'react-i18next';

// import styles from './company-add-edit.module.scss';

const CompanyAddEdit = () => {
    const { t } = useTranslation();
    const { companyId } = useParams();
    const [companyName, setCompanyName] = useState("")

    const pageTitle = companyId ? companyName : t("Add_new_company")

    return (
        <AppMainLayout noBackground navBarTitle={pageTitle}>
            <Heading headingFirst={pageTitle} />
            <div>
                <TabInfo {...{ companyId }} setCompanyName={(e) => setCompanyName(e)} />
            </div>
        </AppMainLayout>
    )
}

export default CompanyAddEdit

