import React, { useState, useEffect } from "react";
import AppMainLayout from '../../infrastructure/layouts/AppMainLayout'
import DataTableComponent from "../../infrastructure/components/DataTableComponent"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SearchIcon } from "../../svg-icons/icon-search"
// import Button from '@mui/material/Button';
import { ExitIcon, Button } from "finergy-kit"
import {
    ordersFilters,
    capitalize
} from "../../constants"
import classNames from "classnames"
import { useSelector } from "react-redux";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { amountFormatter } from "../../constants"
import axios from "../../api/services/Api";
import { UseErrorLabel } from "../../hooks/useErrorLabel"
import { getTokenInHeaders } from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination"

import styles from './orders.module.scss';


const Orders = () => {

    const { t } = useTranslation();

    const [isFetching, setIsFetching] = useState(true);
    const [searchText, setSearchText] = useState("")

    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [rows, setRows] = useState([])
    const [filter, setFilter] = useState("all")
    const [totalCount, setTotalCount] = useState(0)

    const tokenInHeader = useSelector(getTokenInHeaders);

    useEffect(() => {
        setIsFetching(true);
        tokenInHeader && fetchOrders();
    }, [tokenInHeader]);

    const fetchOrders = () => {
        setIsFetching(true);
        const f = filter === "all" ? "" : filter
        axios
            .get(
                `/orders?offset=${currentPage * pageSize
                }&count=${pageSize}&orderState=${f.toUpperCase()}&searchAll=${searchText}`
            )
            .then((res) => {
                const { data } = res;
                console.log("res", res);
                setRows(data.orderList);
                setTotalCount(data.totalCount)
                setIsFetching(false);
                // setCount((test) => test + 1);
            })
            .catch(() => { });
    };
    useEffect(() => {
        setCurrentPage(0);
    }, [filter]);

    useEffect(() => {
        fetchOrders();
    }, [currentPage, pageSize, filter, searchText]);

    
    const renderLicensesCells = () => {
        return [
            {
                field: 'id',
                headerName: '#',
                flex: 1,
                headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return `#${row.orderId}`;
                },
            },
            { field: 'companyName', headerName: t('Company'), flex: 1, headerClassName: styles.table_header, },
            { field: 'companyIdno', headerName: 'IDNO', flex: 1, headerClassName: styles.table_header, },
            {
                field: 'orderType', headerName: t('Operation_type'), flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {

                    return row.companyIdno === "BUY" ? t('Buy') : t('Sale')
                },
            },

            {
                field: "initialAmount",
                headerName: t("Count"),
                flex: 1,
                headerClassName: styles.table_header,
                cellClassName: ({ row }) => {
                    return row.orderState === "ERROR" ? styles.order_status_error : '';
                },
                renderCell: ({ row }) => {
                        return `${amountFormatter(row.currentAmount)} ${row.fromCcy}`;
                },
            },
            {
                field: "currentAmount",
                headerName: t("Rest"),
                flex: 1,
                headerClassName: styles.table_header,
                cellClassName: ({ row }) => {
                    return row.orderState === "ERROR" ? styles.order_status_error : '';
                },
                renderCell: ({ row }) => {
                        return `${amountFormatter(row.currentAmount)} ${row.fromCcy}`;
                },
            },

            { field: 'fixRate', headerName: t('Exchange'), flex: 1, headerClassName: styles.table_header, 
            renderCell: ({ row }) => {
                    return `${amountFormatter(row.fixRate)} MDL`
              
            },
        },

            {
                field: 'whenCreated', headerName: t('Created'), flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return moment(row.whenCreated).format("DD-MM-YYYY HH:mm");
                },
            },
            {
                field: 'orderState', headerName: t('Status'), flex: 1, headerClassName: `${styles.table_header} ${styles.header_action_buttons}`,
                cellClassName: styles.order_status,
                renderCell: ({ row }) => {
                    const label = capitalize(row.orderState.toLowerCase())
                    return UseErrorLabel(label, t);
                },
            },

        ];
    }

    const renderFilterItems = (noBorder = false) => {
        const content = ordersFilters.map((name) => {
            return (
                <button
                    className={classNames({
                        [`${styles.filter_button}`]: !noBorder,
                        [`${styles.filter_button_active}`]: filter === name,
                        [`${styles.filter_dropdown}`]: noBorder,
                        [`${styles.status_label}`]: noBorder,
                    })}
                    onClick={() => setFilter(name)}
                >
                    {t(capitalize(name))}
                </button>
            )
        })

        return content
    }

    const paginationClick = (page) => {
        setIsFetching(true);
        setCurrentPage(page)
    }

    const pageSizeClick = (size) => {
        setIsFetching(true);
        setCurrentPage(0)
        setPageSize(size)
    }

    return (
        <AppMainLayout navBarTitle={t('Orders')} >
            {/* {renderLicesnseDetails()} */}
            {/* {isRedirect && <Redirect to={`/licenses/${redirectId}`} />} */}
            <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
                <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<SearchIcon />}
                    label="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </FormControl>

            <div className={styles.calendar_and_filters_container}>
                {/* <FormControl className={styles.select_period}>
                    <CalendarIcon />
                    <label> Period:</label>
                    <Select
                        value={period}
                        name="selected-period"
                        onChange={(e) => setPeriod(Number(e.target.value))}
                    >
                        <MenuItem value={LAST_MONTH}>Last month</MenuItem>
                        <MenuItem value={LAST_THREE_MONTHS}>Last three months</MenuItem>
                        <MenuItem value={QUARTER}>Quarter</MenuItem>
                        <Divider />
                        <MenuItem value={CUSTOM_PERIOD}>Custom period</MenuItem>
                    </Select>
                </FormControl> */}
                <div className={styles.filter_container}>{renderFilterItems()}</div>
            </div>


            <DataTableComponent
                // onCellClick={(row) => onCellClick(row)}
                checkboxSelection={false}
                columns={renderLicensesCells()}
                isFetching={isFetching}
                idChangeValue={"orderId"}
                {...{ rows }} />
            {!isFetching && <Pagination
                onClick={paginationClick}
                onChangePageSize={pageSizeClick}
                total={totalCount}
                pageIndex={currentPage}
                pageSize={pageSize}
            />}




            <div className={styles.customer_page_bottom_container}>
                <Button
                    variant="secondary"
                    size="l"
                    icon={<ExitIcon />}
                    label={t('Export_as_csv')}
                    iconPosition="right"
                    disabled
                    id={"EXPORT_CSV"}
                />

            </div>
        </AppMainLayout>
    )
}

export default Orders

