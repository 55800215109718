import React, { useEffect, useState } from "react";
import { Logo } from "../../../assets/icons/logo";

import { INavigationComponentProps } from "./types";
import classNames from "classnames";
import { navigation } from "../../../constants/data";
import styles from "./navigation.module.scss";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { ExitIcon } from "../../../svg-icons/icon-exit";
import { useTranslation } from "react-i18next";
import { getIsAuthFetching } from "../../../api/selectors/authSelector";
import {
  getStartMarketHours,
  getMarketState,
} from "../../../api/selectors/mainSelector";
import {
  saveMarketHours,
  onStartMarket,
} from "../../../api/actionCreators/mainActionCreator";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Button } from "finergy-kit";
import CircularProgress from "@mui/material/CircularProgress";
import { CloseRemoveIcon } from "../../../svg-icons/icon-close-remove";
import InputMask from "react-input-mask";
import axios from "../../../api/services/Api";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NavigationComponent({
  link,
  onExit,
  children,
  current,
  className,
}: INavigationComponentProps) {
  const { t } = useTranslation();
  const modalStateByDefault = { state: false, clickedFrom: "" };
  const dispatch = useDispatch();
  const startMarketHours = useSelector(getStartMarketHours);
  const checkedStartMarket = useSelector(getMarketState);
  const isAuthFetching = useSelector(getIsAuthFetching);

  const [open, setOpen] = useState(false);
  const [starStopMarket, setIsStarStopMarket] = useState(false);
  const [isMarkeLoading, setIsMarkeLoading] = useState(true);
  const [openStartMarket, setOpenStartMarket] = useState(modalStateByDefault);
  const [startMarket, setStartMarket] = useState({
    startHours: "",
    endHours: "",
  });

  const pathname = window.location.pathname;

  useEffect(() => {
    axios.get(`/market/state`).then((res) => {
      const {
        data: { isActive = false, plannedStartTime, plannedStopTime },
      } = res;
      const hours = { startHours: plannedStartTime, endHours: plannedStopTime };
      setStartMarket(hours);
      setIsMarkeLoading(false);
      dispatch(saveMarketHours(hours));
      dispatch(onStartMarket(isActive));
    });
  }, []);

  const renderMenu = () => {
    const content = navigation.map(({ linkTitle, url, icon }, index) => {
      const selectedMenu = pathname.includes(url);
      return (
        <Link
          underline="none"
          className={classNames({
            [`${styles.nav_link_wrapper}`]: true,
            [`${styles.active_nav_menu}`]: selectedMenu,
          })}
          to={`${url}`}
          key={index}
        >
          {icon}
          <span className={styles.nav_cta_link}> {t(linkTitle)} </span>
        </Link>
      );
    });

    return content;
  };

  const renderExitModal = () => {
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Logout")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => onExit()}
              variant="tertiary"
              label={t("Exit")}
              size="l"
              disabled={isAuthFetching}
              iconPosition="off"
              icon={
                <CircularProgress className="circular-progress" size={18} />
              }
              iconOnly={isAuthFetching}
              id={"BACK_BUTTON"}
              className={classNames({
                [`${styles.logout_button}`]: true,
              })}
            />
            <Button
              onClick={() => setOpen(false)}
              variant="tertiary"
              label={t("Cancel")}
              // disabled={isServiceModeFetching}
              className={styles.exit_button}
              size="l"
              iconPosition="off"
              id={"ON_EXIT"}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const saveStartMarket = (action) => {
    setIsStarStopMarket(true);
    const params = {
      isActive: action,
      plannedStartTime: startMarket.startHours,
      plannedStopTime: startMarket.endHours,
    };
    axios.put(`/market/state`, { ...params }).then((res) => {
      dispatch(saveMarketHours(startMarket));
      dispatch(onStartMarket(action));
      if (
        startMarket.startHours.length === 0 ||
        startMarket.endHours.length === 0
      ) {
        dispatch(onStartMarket(false));
      }
      setOpenStartMarket(modalStateByDefault);
      setIsStarStopMarket(false);
    });
  };

  const setStarTime = (state, value) => {
    setStartMarket({
      ...startMarket,
      [state]: value,
    });
  };

  const applyHours = () => {
    dispatch(saveMarketHours(startMarket));
    setOpenStartMarket(modalStateByDefault);
  };

  // const isSomeChanges = JSON.stringify(startMarketHours) === JSON.stringify(startMarket)

  const renderStartSalesMarket = () => {
    return (
      <Dialog
        open={openStartMarket.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenStartMarket(modalStateByDefault)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container_start_market}>
          <CloseRemoveIcon
            className={styles.close_icon}
            onClick={() => setOpenStartMarket(modalStateByDefault)}
          />
          <DialogTitle className={styles.start_market_modal_title}>
            {t("Stop_market_now")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.star_market_modal_content}
            >
              <div className={styles.inputs_container}>
                {openStartMarket.clickedFrom !== "STOP" && (
                  <InputMask
                    mask="99:99"
                    value={startMarket.startHours}
                    onChange={(e) => setStarTime("startHours", e.target.value)}
                  >
                    {() => (
                      <TextField
                        placeholder={"09:00"}
                        variant={"outlined"}
                        label={t("Begin")}
                        type={"text"}
                        id={"START_MARKET_INPUT"}
                      />
                    )}
                  </InputMask>
                )}

                <InputMask
                  mask="99:99"
                  value={startMarket.endHours}
                  onChange={(e) => setStarTime("endHours", e.target.value)}
                >
                  {() => (
                    <TextField
                      placeholder={"21:00"}
                      variant={"outlined"}
                      label={t("End")}
                      type={"text"}
                      id={"START_MARKET_INPUT"}
                    />
                  )}
                </InputMask>
              </div>
            </DialogContentText>
          </DialogContent>

          {openStartMarket.clickedFrom !== "STOP" ? (
            <DialogActions className={styles.action_buttons}>
              <Button
                onClick={() => saveStartMarket(true)}
                variant="secondary"
                label={t("Start_market")}
                disabled={starStopMarket}
                className={styles.approve_button}
                size="l"
                iconPosition="off"
                id={"START_MARKET"}
              />
              <Button
                onClick={() => setOpenStartMarket(modalStateByDefault)}
                variant="tertiary"
                label={t("Cancel")}
                size="l"
                disabled={starStopMarket}
                iconPosition="off"
                className={styles.cancel_button}
                id={"CANCEL_BACK_BUTTON"}
              />
            </DialogActions>
          ) : (
            <DialogActions className={styles.action_buttons}>
              <Button
                onClick={() => saveStartMarket(false)}
                variant="secondary"
                label={t("Stop_now")}
                disabled={starStopMarket}
                className={styles.stop_market}
                size="l"
                iconPosition="off"
                // iconOnly={showIsFetchingActiveModal}
                // icon={showIsFetchingActiveModal && <CircularProgress size={20} />}
                id={"STOP_NOW"}
              />
              <Button
                onClick={() => applyHours()}
                variant="tertiary"
                label={t("Apply")}
                size="l"
                disabled={starStopMarket}
                iconPosition="off"
                className={styles.apply_button}
                id={"APPLY"}
              />
            </DialogActions>
          )}
        </div>
      </Dialog>
    );
  };

  const noHours =
    startMarketHours.startHours.length === 0 ||
    startMarketHours.endHours.length === 0;
  const now = moment(new Date()).format("HH:mm");
  const timeNow = now.replace(":", "");
  const endTime = startMarketHours?.startHours.replace(":", "");
  const marketIsActive =
    checkedStartMarket && Number(timeNow) > Number(endTime);

  const renderMarketContent = () => {
    return (
      <>
        <div
          className={classNames({
            [`${styles.run_trades_container}`]: true,
          })}
          //onClick={() => setOpenStartMarket(true)}
        >
          {noHours ? (
            <span className={styles.no_hours}>{t("No_hours")}</span>
          ) : (
            <>
              <div className={styles.run_trades}>
                {marketIsActive ? (
                  <div className={styles.market_on}>
                    • {t("Market_is_active")}
                  </div>
                ) : (
                  <div>
                    <div className={styles.market_off}>
                      • {t("Market_is_inactive")}
                    </div>
                    {Number(timeNow) < Number(endTime) && (
                      <div className={styles.run_trades_text}>
                        {t("Planning_start")} {` `} {startMarketHours.startHours}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.run_trades_text}>
                {marketIsActive ? (
                  <div>
                    {t("Planning_stop")}  {startMarketHours.endHours}
                  </div>
                ) : (
                  Number(timeNow) > Number(endTime) && (
                    <div>
                      {t("Planning_start")}
                      {` `} {startMarketHours.startHours}
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>
        <div>
          {!marketIsActive ? (
            <Button
              onClick={() =>
                setOpenStartMarket({ state: true, clickedFrom: "START" })
              }
              variant="secondary"
              label={t("Start_market")}
              // disabled={isServiceModeFetching}
              className={styles.start_market}
              size="l"
              iconPosition="off"
              id={"START_MARKET"}
            />
          ) : (
            <Button
              onClick={() =>
                setOpenStartMarket({ state: true, clickedFrom: "STOP" })
              }
              variant="secondary"
              label={t("Stop_market")}
              className={styles.stop_market}
              size="l"
              iconPosition="off"
              id={"STOP_MARKET"}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames({
        [`${styles.nav_wrapper}`]: true,
        [className]: true,
      })}
    >
      {renderExitModal()}
      {renderStartSalesMarket()}
      <div className={styles.nav_logo}>
        <Logo />
      </div>

      <div className={styles.divider} />

      <div>
        <div className={styles.nav_menu}>{renderMenu()}</div>
        <div
          onClick={() => setOpen(true)}
          className={classNames({
            [`${styles.nav_link_wrapper}`]: true,
            [`${styles.nav_link_exit}`]: true,
          })}
        >
          <ExitIcon />
          <span>{t("Exit")}</span>
        </div>
      </div>

      <div className={styles.navbar_footer}>
        <div
          className={classNames({
            [`${styles.block_item}`]: true,
            [`${styles.run_trades_parent}`]: true,
            [`${styles.bg_active}`]: marketIsActive,
            [`${styles.fixed_height}`]: isMarkeLoading,
          })}
        >
          {isMarkeLoading ? (
            <CircularProgress className="circular-progress" size={18} />
          ) : (
            renderMarketContent()
          )}
        </div>
        <div className={styles.block_item}>
          <div className={styles.support}>
            {" "}
            {t("Support")}: <a href="tel:+37322555544">+373 22 555 544</a>
          </div>
          <div className={styles.copyright}>
            {" "}
            {t("Build_by")}{" "}
            <a href="https://finergy.md/" target="_blank">
              Finergy Tech.
            </a>
            <br /> {t("All_rights_reserved")}.
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationComponent;
