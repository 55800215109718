import { RootState } from '../../interfaces'

const getMainReducerState = (state: RootState) => {
    return state?.MainReducer;
};

export const getCustomerInfo = (state: RootState) => {
    return getMainReducerState(state).customerData || {};
};

export const getSideDrawerOpenState = (state: RootState) => {
    return getMainReducerState(state).showSideDrawer;
}

export const getSettingsState = (state: RootState) => {
    return getMainReducerState(state).settings;
}

export const getStartMarketHours = (state: RootState) => {
    return getMainReducerState(state).startMarketHours;
}

export const getMarketState = (state: RootState) => {
    return getMainReducerState(state).checkedStartMarket;
}
