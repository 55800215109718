import moment from "moment"

export const LAST_MONTH = 1;
export const LAST_THREE_MONTHS = 2;
export const QUARTER = 3;
export const CUSTOM_PERIOD = 4;
export const LAST_HOUR = 5;
export const LAST_DAY = 6;

export const filterData = [
    { name: "all", showInDropDown: false },
    { name: "new", showInDropDown: true },
    { name: "invoiced", showInDropDown: true },
    { name: "overdue", showInDropDown: true },
    { name: "payed", showInDropDown: true },
    { name: "canceled", showInDropDown: true },
]

export const filters = ["all", "active", "completed", "deleted", "in_process", "expired", "error"]

export const ordersFilters = ["all", "active", "completed", "deleted", "expired"]

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const generateToken = () => {
    let token = "";
    const possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

    for (var i = 0; i < 50; i++)
        token += possible.charAt(Math.floor(Math.random() * possible.length));

    return token;
}

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

// format as ### ###
export const numberFormatter = (number) => Number(number).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$& ');

export const checkDisabledButton = (uploadedData, customerInfo) => {
    const disabled =
        uploadedData.commissionInfo.buyCommission.toString() === customerInfo.commision.values.buyCommission.toString() &&
        uploadedData.commissionInfo.sellCommission.toString() === customerInfo.commision.values.sellCommission.toString() &&
        uploadedData.companyInfo.comment === customerInfo.general.values.description &&
        uploadedData.companyInfo.companyName === customerInfo.general.values.companyName &&
        uploadedData.companyInfo.idno === customerInfo.general.values.idno &&
        uploadedData.directorInfo.directorFullName === customerInfo.users.values.directorNameSurname &&
        uploadedData.directorInfo.idnp === customerInfo.users.values.directorIdnp &&
        uploadedData.directorInfo.phone.toString() === customerInfo.users.values.directorPhone.toString();

    return disabled
}


export const checkIfTokenExpired = (expiredTime) => {
    const now = moment()
    const secDiff = now.diff(expiredTime, "seconds");
    
    const res = (secDiff * -1) < 20 ? true : false
    return res
}