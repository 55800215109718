import React, { useState, useEffect } from "react";
import AppMainLayout from '../../infrastructure/layouts/AppMainLayout'
import DataTableComponent from "../../infrastructure/components/DataTableComponent"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SearchIcon } from "../../svg-icons/icon-search"
// import Button from '@mui/material/Button';
import { ExitIcon, Button, CalendarIcon } from "finergy-kit"
// import { mockLicensesData } from "../../constants/data"
import {
    CUSTOM_PERIOD,
    LAST_MONTH,
    LAST_THREE_MONTHS,
    QUARTER,
} from "../../constants"
import { useDispatch } from 'react-redux';
import { onShowSideDrawer } from '../../api/actionCreators/mainActionCreator';
import SideDrawerComponent from "../../infrastructure/components/SideDrawerComponent"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Divider from "@mui/material/Divider"
import axios from "../../api/services/Api";

import styles from './history-page.module.scss';

const HistoryPageContent = () => {

    // const { rows } = mockLicensesData
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [activationDate, setActivationDate] = useState();
    const [contractDate, setContractDate] = useState();
    const [quantityOfLicenses, setQuantityOfLicenses] = useState(null);
    const [contract, setContract] = useState("");
    const [comment, setComment] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [btnLabel, setBtnLabel] = useState("Save");
    const [period, setPeriod] = useState(2)
    // const [currentPage, setCurrentPage] = useState(0)
    // const [pageSize, setPageSize] = useState(10)
    const [rows, setRows] = useState(null)
    const [redirectId, setRedirectId] = useState(null)
    const [isRedirect, setIsRedirect] = useState(false)

    useEffect(() => {
        setIsFetching(true)
        // const params = {
        //     offset: currentPage * pageSize,
        //     count: pageSize,
        //     searchAll: ""
        // }
        axios.get("/history")
            .then(res => {
                const { data } = res
            
                setRows(data)
                setIsFetching(false)
            })
            .catch(() => {

            });
    }, [])


    const onCellClick = ({ row }) => {
        setRedirectId(row.id)
        setIsRedirect(true)
    }

    const hideAndResetData = () => {
        dispatch(onShowSideDrawer())
        setIsSaved(false)
        setBtnLabel("Saved")
    }

    const saveLicense = () => {
        setIsFetching(true)
        setTimeout(() => {
            setIsFetching(false)
            setIsSaved(true)
            setBtnLabel("Saved")

            setTimeout(() => {
                hideAndResetData()
            }, 500);
        }, 1000);
    }
    const renderLicensesCells = () => {
        return [
            {
                field: 'id',
                headerName: '#',
                flex: 1,
                headerClassName: styles.table_header,
                renderCell: (row) => {
                    return `#${row.value}`;
                },
            },
            { field: 'date', headerName: 'Date', flex: 1, headerClassName: styles.table_header, },
            { field: 'idno', headerName: 'IDNO', flex: 1, headerClassName: styles.table_header, },
            { field: 'company', headerName: 'Компания', flex: 1, headerClassName: styles.table_header, },
            { field: 'dealId', headerName: 'ID Сделки', flex: 1, headerClassName: styles.table_header, },
            {
                field: 'countDebit1', headerName: 'Счёт 1', flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return row.countCredit.number;
                },
            },
            {
                field: 'countDebit2', headerName: 'Валюта', flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return row.countCredit.currency;
                },
            },
            {
                field: 'countDebit3', headerName: 'Сумма', flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return row.countCredit.summ;
                },
            },
            {
                field: 'countCredit4', headerName: 'Счёт 2', flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return row.countCredit.number;
                },
            },
            {
                field: 'countCredit5', headerName: 'Валюта', flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return row.countCredit.currency;
                },
            },
            {
                field: 'countCredit6', headerName: 'Сумма', flex: 1, headerClassName: styles.table_header,
                renderCell: ({ row }) => {
                    return row.countCredit.summ;
                },
            },

            { field: 'description', headerName: 'Курс обмена', flex: 1, headerClassName: styles.table_header, },

        ];
    }

    const renderLicesnseDetails = () => {
        return (
            <SideDrawerComponent
                title={"Add new licenses"}
                onClick={() => saveLicense()}
                isFetching={isFetching}
                isSaved={isSaved}
                btnLabel={btnLabel}
            >
                <div className={styles.licenses_container}>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Quantity of licenses*</InputLabel>
                        <Select
                            className="select-lang-selector"
                            label="Quantity of licenses*"
                            value={quantityOfLicenses}
                            onChange={(e) => setQuantityOfLicenses(e.target.value)}
                        >
                            <MenuItem value={100} key={0}>100</MenuItem>
                            <MenuItem value={500} key={1}>500</MenuItem>
                            <MenuItem value={1000} key={2}>1000</MenuItem>
                            <MenuItem value={1500} key={3}>1500</MenuItem>
                            <MenuItem value={2000} key={4}>2000</MenuItem>
                            <MenuItem value={2500} key={5}>2500</MenuItem>
                        </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                label="Activation date"
                                value={activationDate}
                                onChange={(newValue) => setActivationDate(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    <TextField
                        style={{ width: "100%" }}
                        className="text-input"
                        placeholder={"Contract #"}
                        id="outlined-basic"
                        variant="outlined"
                        label={"Contract #"}
                        type={"text"}
                        value={contract}
                        onChange={(e) => setContract(e.target.value)}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                label="Contract date"
                                value={contractDate}
                                onChange={(newValue) => setContractDate(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    <TextField
                        style={{ width: "100%" }}
                        className={styles.textarea}
                        placeholder={"Comment"}
                        id="outlined-basic"
                        variant="outlined"
                        multiline
                        rows={7}
                        label={"Comment"}
                        type={"text"}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>


            </SideDrawerComponent>
        )
    }

    return (
        <AppMainLayout navBarTitle={'License management'} >
            {renderLicesnseDetails()}
            {isRedirect && <Redirect to={`/licenses/${redirectId}`} />}
            <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
                <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<SearchIcon />}
                    label="Search"
                />
            </FormControl>

            <div className={styles.calendar_and_filters_container}>
                <FormControl className={styles.select_period}>
                    <CalendarIcon />
                    <label> Period:</label>
                    <Select
                        value={period}
                        name="selected-period"
                        onChange={(e) => setPeriod(Number(e.target.value))}
                    >
                        <MenuItem value={LAST_MONTH}>Last month</MenuItem>
                        <MenuItem value={LAST_THREE_MONTHS}>Last three months</MenuItem>
                        <MenuItem value={QUARTER}>Quarter</MenuItem>
                        <Divider />
                        <MenuItem value={CUSTOM_PERIOD}>Custom period</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <DataTableComponent
                onCellClick={(row) => onCellClick(row)}
                checkboxSelection={false}
                columns={renderLicensesCells()}
                isFetching={isFetching}
                {...{ rows }} />

            <div className={styles.customer_page_bottom_container}>
                <Button
                    variant="secondary"
                    size="l"
                    icon={<ExitIcon />}
                    label={t('Export_as_csv')}
                    iconPosition="right"
                    id={"EXPORT_CSV"}
                />

            </div>
        </AppMainLayout>
    )
}

export default HistoryPageContent

