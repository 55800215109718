import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getSettingsState } from "../../../api/selectors/mainSelector"
// import { getMaintenanceModeStatus } from "../../../api/selectors/authSelector"
import { Redirect } from 'react-router-dom';
import NavigationComponent from "../../components/NavigationComponent"
import TopNavBarComponent from "../../components/TopNavBarComponent";
import WarningBanner from "../../components/WarningBanner";
import axios from "../../../api/services/Api";
import {
    removeToken,
    removeUserData,
    removeExpiredTime,
    removeRefreshToken,
    removeMaintenanceModeStatus,
    getMaintenanceModeStatus
} from "../../../api/services/Auth";
import {
    logoutStart,
    logoutFinish,
    setToken,
    setExpiredTime,
    signIn,
    setTokenInHeaders,
    setModeStatus
} from '../../../api/actionCreators/authActionCreator';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { IAppMainLayoutProps } from "./types"
import classNames from "classnames"

import styles from './app-main-layout.module.scss';

function AppMainLayout({ navBarTitle, children, noBackground, className }: IAppMainLayoutProps) {
    const [redirect, setRedirect] = useState(false)
    const [mode, setMode] = useState(false)
    const dispatch = useDispatch();

    const status = useSelector(getMaintenanceModeStatus);
    useEffect(() => {

        dispatch(setModeStatus(status))
        setMode(status === "true")
    }, [status])

    const clearData = () => {
        removeExpiredTime()
        removeRefreshToken()
        removeToken()
        removeUserData()
        removeMaintenanceModeStatus()

        dispatch(logoutFinish())
        dispatch(setToken({ accessToken: "", refreshToken: "", expiresIn: "" }))
        dispatch(setExpiredTime(null))
        dispatch(signIn(null))

        dispatch(setTokenInHeaders(false))
        setRedirect(true)
    }

    const onExit = () => {
        dispatch(logoutStart())
        axios.post("/logout")
            .then(() => {
                clearData()
            })
    }

    return (
        <>
            {redirect && <Redirect to="/" />}
            <div
                className={classNames({
                    [`${styles.main_section}`]: true,
                    [`${className}`]: true,
                })}>

                <div className={styles.main_grid}>
                    <NavigationComponent {...{ onExit }} />
                    <div>
                        {mode && <WarningBanner />}
                        <TopNavBarComponent navBarTitle={navBarTitle} />
                        <Box>
                            <Paper
                                elevation={1}
                                className={classNames({
                                    [`${styles.w100}`]: true,
                                    [`${styles.background_none}`]: noBackground,
                                })}
                            >
                                {children}
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppMainLayout;
