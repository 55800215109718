import React, { useState } from 'react';
import { IWarningBannerProps } from "./types"
import { Button } from "finergy-kit"
import { ManufactureIcon } from "../../../svg-icons/icon-manufacture"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CircularProgress from '@mui/material/CircularProgress';
import { CloseRemoveIcon } from '../../../svg-icons/icon-close-remove';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsState } from '../../../api/selectors/mainSelector';
import { setMaintenanceModeStatus } from "../../../api/services/Auth"
import axios from "../../../api/services/Api";
import { useTranslation } from 'react-i18next';
import { setModeStatus } from '../../../api/actionCreators/authActionCreator';

import styles from "./warning-banner.module.scss"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function WarningBanner({ }: IWarningBannerProps) {

    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [showActiveModal, setShowActiveModal] = useState(false)
    const [showIsFetchingActiveModal, setShowIsFetchingActiveModal] = useState(false)

    const settings = useSelector(getSettingsState);

    const setOnSite = () => {
        setShowIsFetchingActiveModal(true)
        const { comissionForBuy, comissionForSale, } = settings

        const obj = {
            activeServiceMode: false,
            buyCommission: comissionForBuy,
            sellCommission: comissionForSale,
        }

        axios.put(`/settings`, { ...obj })
            .then(() => {
                setMaintenanceModeStatus(false)
                dispatch(setModeStatus(false))
                setTimeout(() => {
                    setShowActiveModal(false)
                }, 1000);
            })

    }

    const hideModal = () => {
        setShowActiveModal(false)
        setShowIsFetchingActiveModal(false)
    }
    const renderServiceModeModal = () => {
        return (
            <Dialog
                open={showActiveModal}
                TransitionComponent={Transition}
                keepMounted
                className={styles.service_modal}
                onClose={() => setShowActiveModal(false)}
                aria-describedby="alert-dialog-slide-description"

            >
                <div className={styles.custom_modal_container}>
                    <CloseRemoveIcon className={styles.close_icon} onClick={() => setShowActiveModal(false)} />
                    <DialogTitle className={styles.setting_modal_title}>

                        {t('Start_platform_work')}
                    </DialogTitle>
                    <DialogContent className={styles.modal_content}>
                        <span>
                           {t('Are_you_sure_to_start_work')}
                        </span>
                    </DialogContent>
                    <DialogActions className={styles.action_buttons}>
                        <Button
                            onClick={() => setOnSite()}
                            variant='secondary'
                            label={t('Start_work')}
                            disabled={showIsFetchingActiveModal}
                            className={styles.approve_button}
                            size="l"
                            iconPosition="off"
                            iconOnly={showIsFetchingActiveModal}
                            icon={showIsFetchingActiveModal && <CircularProgress size={20} />}
                            id={"ACCEPT_WARNING_MODAL"}
                        />
                        <Button
                            onClick={() => hideModal()}
                            variant='tertiary'
                            label={t('Cancel')}
                            size="l"
                            iconPosition="off"
                            className={styles.cancel_button}
                            id={"CANCEL_WARNING_MODAL"}
                        />
                    </DialogActions>
                </div>

            </Dialog>
        )
    }


    return (
        <div className={styles.warning_wrapper}>
            {renderServiceModeModal()}
            <label>
                <ManufactureIcon />
                {t('Service_mode_alert')}

            </label>
            <Button
                onClick={() => setShowActiveModal(true)}
                label={t("Start_work")}
                size="l"
                variant="secondary"
                iconPosition='off'
                className={styles.star_proccess}
                id={"START_WORK_WARNING_MODAL"}
            />
        </div>
    );
};

export default WarningBanner;
