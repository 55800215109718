import {
    SET_CUSTOMER_INFO,
    UPLOAD_COMPANY_INFO,
    SHOW_SIDE_DRAWER,
    SAVE_SETTINGS,
    SAVE_MARKET_HOURS,
    ON_START_MARKET
} from '../actions';
import { AnyAction } from 'redux'
import { fieldsGeneral, fieldsUser, fieldsCounts, fieldsCommision } from "../../constants/data"

const initialState = {
    customerData: {
        general: fieldsGeneral,
        users: fieldsUser,
        // billingInfo: fieldsBillingInfo,
        counts: fieldsCounts,
        commision: fieldsCommision
    },
    showSideDrawer: false,
    settings: {
        activeServiceMode: false,
        // currentLang: "ro",
        comissionForBuy: "",
        comissionForSale: "",
    },
    startMarketHours: {
        startHours: "",
        endHours: "",
    },
    checkedStartMarket: false

};

export default function addressesReducer(state = initialState, action: AnyAction) {
    switch (action.type) {

        case SAVE_MARKET_HOURS:
            return {
                ...state,
                startMarketHours: {
                    ...action.payload
                }
            }

        case ON_START_MARKET:
            return {
                ...state,
                checkedStartMarket: action.payload
            }
            
        case UPLOAD_COMPANY_INFO:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    ...action.payload
                }
            }
        case SHOW_SIDE_DRAWER:
            return {
                ...state,
                showSideDrawer: !state.showSideDrawer
            }
        case SAVE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...action.payload
                }
            }

        case SET_CUSTOMER_INFO:
            const block = action.payload.block;
            const stateName = action.payload.stateName;
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    [block]: {
                        ...state.customerData[block],
                        values: {
                            ...state.customerData[block].values,
                            [stateName]: action.payload.value
                        }
                    }


                }
            }

        default:
            return state;
    }
}